<template>
	<div class="container">
		<div class="content">
			<div class="content_head">
				<div class="inner">
					<div class="breadcrumb">
						<ul>
							<li><a href="#" class="ico_home" title="Home"></a></li>
							<li>서비스 신청</li>
						</ul>
					</div>
					<h2 class="page_title">서비스 신청</h2>
				</div>
			</div>
			<div class="content_body">
				<div class="inner">
					<div class="apply_visual">
						<div class="text_wrap">
							<strong class="tit">잠들어있는 <b>데이터를 분석</b>하여<br> <b>인사이트</b>를 얻으세요.</strong>
							<p class="desc">궁금한 사항은 문의하기를 이용해 주세요</p>
						</div>
						<a href="#" class="btn_apply">문의하기</a>
					</div>
					<!-- 페이지 단계 -->
					<nav class="page_step">
						<ul class="tab_list">
							<!-- 해당 페이지의 탭에 active 클래스 추가 -->
							<li class="tab_item"><span class="num">1</span>기본 정보</li>
							<li class="tab_item"><span class="num">2</span>데이터 및 결제 정보</li>
							<li class="tab_item active"><span class="num">3</span>입력 정보 확인</li>
						</ul>
					</nav>
					<!-- 입력정보 테이블 -->
					<div class="tbl_basic">
						<table>
							<tr>
								<th>주문번호</th>
								<td>{{ serviceApplyVO.orderNumber }}</td>
							</tr>
							<tr>
								<th>프로젝트명</th>
								<td>{{ serviceApplyVO.projectName }}</td>
							</tr>
							<tr>
								<th>회사명</th>
								<td>{{ serviceApplyVO.companyName }}</td>
							</tr>
							<tr>
								<th>담당자명</th>
								<td>{{ serviceApplyVO.managerName }}</td>
							</tr>
							<!-- 230328수정 -->
							<tr>
								<th>상품명</th>
								<td>{{ serviceApplyVO.companyName }} 빅데이터 플랫폼 구축</td>
							</tr>
							<tr>
								<th>수집 요청 채널</th>
								<td>{{ filterName() }}</td>
							</tr>
							<tr>
								<th>레포트 바탕색</th>
								<td>{{ serviceApplyVO.backgroundColor }}</td>
							</tr>
							<tr>
								<th>회사로고</th>
								<td>{{ filename }}</td>
							</tr>
							<tr>
								<th>수집 요청 기간</th>
								<td>{{ serviceApplyVO.reqDate }}</td>
							</tr>
							<template v-if="serviceApplyVO.reqChannel==='cafe'">
								<tr>
									<th rowspan="4">카페24 API KEY 정보</th>
									<td>{{ serviceApplyVO.clientId }}</td>
								</tr>
								<tr>
									<td>{{ serviceApplyVO.secretKey }}</td>
								</tr>
								<tr>
									<td>{{ serviceApplyVO.mailId }}</td>
								</tr>
								<tr>
									<td>{{ serviceApplyVO.redirectUrl }}</td>
								</tr>
							</template>
							<!-- 고도몰 -->
							<template v-if="serviceApplyVO.reqChannel==='godo'">
								<tr>
									<th rowspan="2">고도몰 정보</th>
									<td>{{ serviceApplyVO.partnerId }}</td>
								</tr>
								<tr>
									<td>{{ serviceApplyVO.secretKey }}</td>
								</tr>
							</template>
							<!-- erp -->
							<template v-if="serviceApplyVO.reqChannel==='erp'">
								<tr>
									<th rowspan="1">ERP 정보</th>
									<td>{{ serviceApplyVO.erpName }}</td>
								</tr>
							</template>
							<!-- 고객행동데이터 -->
							<template v-if="serviceApplyVO.reqChannel==='data'">
								<tr>
									<th rowspan="1">고객행동데이터 정보</th>
									<td>{{ serviceApplyVO.webUrl }}</td>
								</tr>
							</template>
							<tr>
								<th>요청사항</th>
								<td>{{ serviceApplyVO.reqMsg }}</td>
							</tr>
						</table>
					</div>
					<!-- 납부방법 테이블 (처음 등록)-->
					<div class="tbl_basic" v-if="!haveBillInfo && serviceApplyVO.reqChannel!=='datapresso'">
						<table>
							<tr>
								<th>납부방법 선택</th>
								<td>신용카드 자동납부</td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<th>예금주</th>
								<td>{{ userInfo?.companyName }}</td>
								<th>금액</th>
								<td>99,000 원</td>
							</tr>
							<tr>
								<th>전화번호</th>
								<td>{{ userInfo?.phoneNumber }}</td>
								<td></td>
								<td></td>
							</tr>
						</table>
					</div>

                    <template v-if="serviceApplyVO.reqChannel!=='datapresso'">
					<!-- 납부방법 테이블 (기존 정보) -->
					<div class="tbl_basic" v-if="haveBillInfo">
						<table>
							<tr>
								<th>납부방법 선택</th>
								<td>신용카드 자동납부</td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<th>예금주</th>
								<td>{{ billInfo?.userName }}</td>
<!--								<th>금액</th>-->
<!--								<td>99,000 원</td>-->
							</tr>
							<tr>
								<th>전화번호</th>
								<td>{{ billInfo?.phoneNumber }}</td>
								<td></td>
								<td></td>
							</tr>
						</table>
					</div>

					<!-- 이용약관 -->
					<div class="apply_terms">
						<strong>서비스 이용료</strong>
						1. 서비스 개시일 확정일로부터 매월 정기결제가 진행됩니다.<br>
						2. 플랫폼 오픈 이벤트 기간 동안 모든 고객에게 정해진 기간동안 무료로 플랫폼 서비스를 제공해 드립니다.<br>
						3. 무료체험 기간이 종료된 이후에는 월 서비스 이용료 99,000원이 정기결제 됩니다.<br>
						4. 오픈 이벤트 기간 동안에는 인프라 사용료와 구축비용을 청구하지 않습니다.<br>
						5. 이벤트 기간은 정해져있지 않지만 이벤트 종료 1개월 전에 사전 공지드립니다.<br>
						<br>
						<strong>정기결제</strong>
						1. 서비스 개시일(무료이벤트 기간중에는 무료기간을 마친직후)로부터 매월 자동으로 카드 결제됩니다.<br>
						2. 결제 수단은 카드만 가능하며 다른 결제 수단을 원하시는 경우 따로 문의 부탁드립니다.<br>
						<br>
						<strong>해지 및 환불</strong>
						1. 해지 신청을 하시면 익일 반영되며 사용한 기간에 계산하여 환불해드립니다.<br>
						2. 해지 신청은 자동 결제 최대 2일 전에 하셔야 추가 결제가 진행되지 않습니다.<br>
						3. 해지가 완료되고 난 후 서비스 종료일 이후에 모든 데이터와 빅데이터 인프라는 삭제됩니다.<br>
					</div>
					<!-- 이용금액 -->
					<div class="apply_charge">
						<!-- <div class="charge_service charge_item">
							<strong>서비스 이용 금액</strong>
							<ul>
								<li>
									<div class="lt">월 서비스 이용료</div>
									<div class="rt"><span>99,000</span> 원</div>
								</li>
								<li>
									<div class="lt">월 인프라 사용료</div>
									<div class="rt"><span>10,000</span> 원</div>
								</li>
								<li>
									<div class="lt">구축비용</div>
									<div class="rt"><span>1,000,000</span> 원</div>
								</li>
								<li class="total">
									<div class="lt">합계(VAT 별도)</div>
									<div class="rt"><span>1,160,000</span> 원</div>
								</li>
							</ul>
						</div> 230328수정-->
<!--						<div class="charge_total charge_item">-->
<!--							<strong>결제 금액</strong>-->
<!--							<p>오픈 이벤트 기간에는 무료 체험 기회를 제공해 드립니다.</p>-->
<!--							<div class="total"><span>0</span> 원</div>-->
<!--						</div>-->
					</div>
					<!-- 이용 동의 -->
					<div class="apply_aggre">
						<div class="check_box">
							<input type="checkbox" name="keep" id="agree1" class="inp_check" v-model="agreeAutoPayment">
							<label for="agree1" class="lab_check">서비스 이용료는 매월 자동결제(컨설팅 서비스의 경우 1회만 결재)되며 이용료는 서비스 개시일 한달뒤에 이루어집니다</label>
						</div>
						<div class="check_box">
							<input type="checkbox" name="keep" id="agree2" class="inp_check" v-model="infoConfirm">
							<label for="agree2" class="lab_check">상시 서비스 이용료 및 안내 내용을 확인하였습니다.</label>
						</div>
					</div>
                    </template>
					<div class="button_area">
						<button type="button" class="btn_cancle btn_black" @click="goBack">수정하기</button>
						<button type="submit" class="btn_submit btn_primary" @click="doSubmit">신청하기</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: "applyCheck",
	data() {
		return {
			serviceApplyVO: new this.ServiceApplyVO(this.getSessionProc()),
			userInfo: this.getSessionProc().userInfo,
			orderDate: "",
			filename: "",

			infoConfirm: false,
			agreeAutoPayment: false,

			haveBillInfo: false,
			billInfo: {}
		}
	},
	created() {

	},
	mounted() {
		//this.getBillInfo();
		this.getServiceBillInfo();
		this.setFileName();
	},
	methods: {
		getServiceBillInfo () {
			const self = this;

			if( this.isLogin() ) {

				let emailId = this.getSessionProc().emailId;

				this.axiosCaller.get(this, this.APIs.SERVICE_APPLY + "/getServiceBillInfo", { 'emailId' : emailId }, (res) => {
					let result = res.data;
					if (result.status === "ok") {

						if( result.haveBillInfo ) {
							self.haveBillInfo = true;
							self.billInfo = result.pgBillInfo;
						}

					} else {
						this.swalUtils.gritter("Error", result.msg, "error")
								.then(() => {
									location.href = document.referrer
								})
					}
				});
			}

		},

		doSubmit() {

			const self = this;

			if (!this.isLogin()) { return self.swalUtils.gritter("로그인이 필요합니다", "", "error", 800) }

            if( this.serviceApplyVO.reqChannel !== 'datapresso' ) {
                if (!this.agreeAutoPayment) {
                    return self.swalUtils.gritter("자동 결제에 동의해주세요", "", "warning", 800)
                }
                if (!this.infoConfirm) {
                    return self.swalUtils.gritter("서비스 이용료 및 안내 내용을 확인해주세요", "", "warning", 800)
                }
            }
			//console.log(':::::serviceApplyVO', self.serviceApplyVO)

			this.axiosCaller.post(this, this.APIs.SERVICE_APPLY + "/doApplyService", this.serviceApplyVO, (res) => {
				self.$eventBus.$emit("doneProgress")
				const result = res.data
				console.log(result)
				if (result.status === "ok") {
					self.swalUtils.gritter(result.msg, "", "success", 800)
							.then( () => {
								let sService =this.getSessionProc();
								sService.serviceApplyVO =  new this.ServiceApplyVO();
								sService.userInfo = {};
								this.setSessionProc(sService)

								location.href='/'
							});
				} else {
					self.swalUtils.gritter(result.msg, "", "error", 800)
				}
			});
		},

		goBack() {
			this.$router.push({path: '/service/data'})
		},

		getBillInfo() {
			const self = this;

			if(this.serviceApplyVO.orderNumber=="") {
				self.swalUtils.gritter("잘못된 접근입니다.", "", "error", 800)
						.then( () => {
							self.$router.push('/service/info')
						});
			}

			this.axiosCaller.get(this, this.APIs.PG_BILL + "/getPgBillInfo", {orderNumber: this.serviceApplyVO.orderNumber}, (res) => {
				this.$eventBus.$emit("doneProgress")
				const result = res.data
				console.log(result)
				if (result.status === "ok") {
					console.log('ok')
				} else {
					self.swalUtils.gritter("잘못된 접근입니다.", "", "error", 800)
							.then( () => {
								self.$router.push('/service/info')
							});
				}
			})
		},

		setFileName() {
			const self = this;

			if (this.serviceApplyVO.fidx != null && this.serviceApplyVO.fidx > 0) {
				//file 가져오기
				this.axiosCaller.post(this, this.APIs.BOARD_FILES + "/findFile", {fidx: this.serviceApplyVO.fidx}, (res) => {
					this.$eventBus.$emit("doneProgress")
					const result = res.data
					console.log(result)
					if (result.status === "ok") {
						if (result) {
							self.filename = result.fileVO.filename;
						}
					} else {
						self.swalUtils.gritter(result.msg, "", "error", 800)
					}
				});
			}
		},

		filterName() {
			switch (this.serviceApplyVO.reqChannel) {
				case "cafe":
					this.serviceApplyVO.partnerId="";
					this.serviceApplyVO.erpName="";
					this.serviceApplyVO.webUrl="";
					return "카페24";
				case "godo":
					this.serviceApplyVO.cliendId="";
					this.serviceApplyVO.mailId="";
					this.serviceApplyVO.redirectUrl="";
					this.serviceApplyVO.erpName="";
					this.serviceApplyVO.webUrl="";
					return "고도몰/샵바이";
				case "erp":
					this.serviceApplyVO.cliendId="";
					this.serviceApplyVO.secretKey="";
					this.serviceApplyVO.mailId="";
					this.serviceApplyVO.redirectUrl="";
					this.serviceApplyVO.partnerId="";
					this.serviceApplyVO.webUrl="";
					return "ERP";
				case "data":
					this.serviceApplyVO.cliendId="";
					this.serviceApplyVO.secretKey="";
					this.serviceApplyVO.mailId="";
					this.serviceApplyVO.redirectUrl="";
					this.serviceApplyVO.partnerId="";
					this.serviceApplyVO.erpName="";
					return "고객행동데이터";
				case "etc":
					this.serviceApplyVO.cliendId="";
					this.serviceApplyVO.secretKey="";
					this.serviceApplyVO.mailId="";
					this.serviceApplyVO.redirectUrl="";
					this.serviceApplyVO.partnerId="";
					this.serviceApplyVO.erpName="";
					this.serviceApplyVO.webUrl="";
					return "기타";
				case "shop":
					this.serviceApplyVO.cliendId="";
					this.serviceApplyVO.secretKey="";
					this.serviceApplyVO.mailId="";
					this.serviceApplyVO.redirectUrl="";
					this.serviceApplyVO.partnerId="";
					this.serviceApplyVO.erpName="";
					this.serviceApplyVO.webUrl="";
					return "샵링커";
				case "ensol":
					this.serviceApplyVO.cliendId="";
					this.serviceApplyVO.secretKey="";
					this.serviceApplyVO.mailId="";
					this.serviceApplyVO.redirectUrl="";
					this.serviceApplyVO.partnerId="";
					this.serviceApplyVO.erpName="";
					this.serviceApplyVO.webUrl="";
					return "에너지솔루션";
				case "anal":
					this.serviceApplyVO.cliendId="";
					this.serviceApplyVO.secretKey="";
					this.serviceApplyVO.mailId="";
					this.serviceApplyVO.redirectUrl="";
					this.serviceApplyVO.partnerId="";
					this.serviceApplyVO.erpName="";
					this.serviceApplyVO.webUrl="";
					return "검색어분석";
				case "consul":
					this.serviceApplyVO.cliendId="";
					this.serviceApplyVO.secretKey="";
					this.serviceApplyVO.mailId="";
					this.serviceApplyVO.redirectUrl="";
					this.serviceApplyVO.partnerId="";
					this.serviceApplyVO.erpName="";
					this.serviceApplyVO.webUrl="";
					return "컨설팅";
                case "datapresso":
                    this.serviceApplyVO.cliendId = "";
                    this.serviceApplyVO.secretKey = "";
                    this.serviceApplyVO.mailId = "";
                    this.serviceApplyVO.redirectUrl = "";
                    this.serviceApplyVO.partnerId = "";
                    this.serviceApplyVO.erpName = "";
                    this.serviceApplyVO.webUrl = "";
                    return "데이터프레소";
			}
		},
	}

}
</script>

<style scoped>

</style>