<template>
	<apply-check></apply-check>
</template>

<script>
import ApplyCheck from "@/components/apply/applyCheck";
export default {
	name: "apply_check",
	components: {ApplyCheck}
}
</script>

<style scoped>

</style>